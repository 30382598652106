@import '~bootstrap/scss/bootstrap';

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 10; /* Behind the navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-heading {
    text-transform: uppercase;
    font-weight: bold;
}

.checkbox-element {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

input.form-check-input {
    margin-right: 0.5em;
}

h1 {
    text-align: center;
}

.overlay {
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    z-index: 100;
    position: fixed;
    opacity: 1;
}

input {
    pointer-events: auto;
}

.inputUnit {
    font-weight: bold;
}
